import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import React from "react";
import SelectedMobile from "./SelectedMobile";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import language from "../../../languages/en";
import { convertUrl } from "../../../utils/imgUrl";
const useStyles = makeStyles((theme) => ({
  image: {
    borderRadius: "10px",
    maxWidth: "100%",
  },
  thumbnail: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  selectList: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2rem",
  },
  itemSelect: {
    margin: "0 10px",
    cursor: "pointer",
  },
  description: {
    height: "65px",
    whiteSpace: "pre-line",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "16px",
  },
  square: {
    verticalAlign: "super",
    fontSize: "9px",
  },
  client: {
    textAlign: "right",
  },
  number: {
    textAlign: "right",
  },
}));

const SelectedItem = (props) => {
  const classes = useStyles();
  const index = props.index;
  const projectList = props.projectList;
  const lang = useSelector((state) => state.lang.lang);
  const selected = !lang
    ? language.en.homepage.selectProject
    : language.vi.homepage.selectProject;

  const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return { width };
  };

  const convertNumberEN = (str) => {
    if (str) {
      const number = parseInt(str.replace(/,/g, ""), 10);
      return number.toLocaleString("en-US");
    }
  };
  const convertNumberVI = (str) => {
    if (str) {
      const number = parseInt(str.replace(/,/g, ""), 10);
      return number.toLocaleString("el-GR");
    }
  };
  const titleConvert = (title) => {
    if (title) {
      let output = title.toLowerCase();
      output = output.replace(/[^\w ]+/g, "");
      output = output.replace(/ +/g, "-");
      return output;
    }
  };

  const viewPort = useViewport();
  const isMobile = viewPort.width <= 1024;
  if (isMobile) {
    return <SelectedMobile projectList={projectList} index={index} />;
  }
  return (
    <div>
      <Grid container spacing={2} style={{ padding: "0 30px" }}>
        <Grid
          item
          xs={6}
          className={classes.thumbnail}
          style={{
            backgroundImage: `url(${convertUrl(projectList[index]?.acf.thumbnail_image)})`,
            backgroundSize: `100%`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          data-aos="fade-up"
          data-aos-easing="ease"
          data-aos-duration="1000"
        ></Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <div
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    data-aos-duration="1000"
                  >
                    <img
                      src={convertUrl(projectList[index]?.acf.photo_gallery.selected_image[0][0].full_image_url)}
                      className={classes.image}
                      alt=""
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <img
                        src={convertUrl(projectList[index]?.acf.photo_gallery.selected_image[0][1].full_image_url)}
                        className={classes.image}
                        alt=""
                        data-aos="fade-up"
                        data-aos-easing="ease"
                        data-aos-duration="1000"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <img
                        src={convertUrl(projectList[index]?.acf.photo_gallery.selected_image[0][2].full_image_url)}
                        className={classes.image}
                        alt=""
                        data-aos="fade-up"
                        data-aos-easing="ease"
                        data-aos-duration="1000"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={1.8}
                style={{ fontSize: "11px", fontStyle: "italic" }}
              >
                <Grid
                  item
                  xs={4}
                  style={{ fontSize: "11px", textAlign: "justify" }}
                >
                  <div
                    style={{ height: "150px" }}
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  >
                    <h3 style={{ textAlign: "left" }}>
                      {projectList[index]?.title.rendered}
                    </h3>
                    <p className={classes.description} dangerouslySetInnerHTML={{
                      __html: lang
                        ? projectList[index]?.acf.description_vi
                        : projectList[index]?.content.rendered
                    }}>


                    </p>
                    <Link
                      to={`/projects/${projectList[index]?.slug}`}
                      style={{
                        color: "#fe5600",
                        fontStyle: "italic",
                        fontSize: "13px",
                      }}
                    >
                      {" "}
                      {lang ? "Xem thêm ..." : "Read more ..."}
                    </Link>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ fontSize: "11px" }}
                  data-aos="fade-up"
                  data-aos-easing="ease"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <div
                    style={{
                      display: "flex",
                      lineHeight: "16px",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>{selected.client}</p>

                    <b className={classes.client}>
                      {projectList[index]?.acf.client}
                    </b>

                  </div>

                  <div
                    style={{
                      display: "flex",
                      lineHeight: "16px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>{selected.location}:</div>
                    <b>
                      {lang
                        ? projectList[index]?.acf.location_vi
                        : projectList[index]?.acf.location_en}
                    </b>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ fontSize: "12px" }}
                  data-aos="fade-up"
                  data-aos-easing="ease"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <div
                    style={{
                      display: "flex",
                      lineHeight: "16px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>{selected.lotArea}:</div>
                    <b>
                      {projectList[index]?.acf.lot_area}{" "}
                      m<span className={classes.square}>2</span>
                    </b>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      lineHeight: "16px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>{selected.conArea}:</div>
                    <b>
                      {projectList[index]?.acf.construction_area}{" "}
                      m<span className={classes.square}>2</span>
                    </b>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      lineHeight: "16px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>{selected.publicArea}:</div>
                    <b>
                      {projectList[index]?.acf.public_area}{" "}
                      m<span className={classes.square}>2</span>
                    </b>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      lineHeight: "16px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>{selected.stories}:</div>
                    <b className={classes.number}>
                      {projectList[index]?.acf.stories_en}
                    </b>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      lineHeight: "22px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>{selected.style}:</div>
                    <b>
                      {lang
                        ? projectList[index]?.acf.style_vi
                        : projectList[index]?.acf.style_en}
                    </b>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectedItem;
