import { combineReducers, configureStore } from "@reduxjs/toolkit";
import langSlice from "./langSlice";
// import {
//     persistStore,
//     persistReducer,
//     FLUSH,
//     REHYDRATE,
//     PAUSE,
//     PERSIST,
//     PURGE,
//     REGISTER,
// } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import { productReducer } from "./reducers/productReducers";

// const persistConfig = {
//     key: "root",
//     version: 1,
//     storage,
// };
// const rootReducer = combineReducers({
//     langSlice
// });

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    //   reducer: persistedReducer,
    //   middleware: (getDefaultMiddleware) =>
    //     getDefaultMiddleware({
    //       serializableCheck: {
    //         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    //       },
    //     }),
    reducer: {
        lang: langSlice
    }
});

// export let persistor = persistStore(store);
