import React, { useState, useCallback } from "react";
// import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { makeStyles } from "@mui/styles";
import { convertUrl } from "../../utils/imgUrl";
// import { photos } from "./photos";

const useStyles = makeStyles((theme) => ({
    image: {
        // margin: 2,
        // height: "360px",
        width: "350px",
        transition: "transform 1s ease",
        cursor: "pointer",
        "&:hover": {
            transform: "scale(1.1)"
        }
    },
    containerImage: {
        // height: "360px",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 2,
        // marginLeft: 'auto',
        // marginRight: 'auto'
        // borderRadius: "8px"
    }
}))
const GalleryImages = ({ margin, photos }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const classes = useStyles()
    const renderImage = useCallback(
        ({ index, left, top, key, photo }) => {
            // console.log(photo);
            const { full_image_url } = photo
            // console.log(photo);
            return (
                <div key={key} className={classes.containerImage}>
                    <img

                        alt=""
                        {...photo}
                        src={full_image_url}
                        className={classes.image}
                        onClick={() => {
                            setCurrentImage(index)
                            setViewerIsOpen(true)
                        }}
                    />
                </div>
            )
        },
        []
    );
    return (
        <div >

            <Gallery photos={photos} renderImage={renderImage} />
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox} >
                        <Carousel
                            currentIndex={currentImage}

                            views={photos.map(x => {
                                // console.log(x);
                                const { full_image_url } = x
                                console.log(full_image_url);
                                return ({
                                    ...x,
                                    src: full_image_url,
                                    srcset: x.srcSet,
                                    caption: x.title,
                                    background: "white"
                                })
                            })}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
    )
}

export default GalleryImages