import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import { listImageAboutUs } from "../../utils/imageAboutUs";
import Employee from "./Employee";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";
import language from "../../languages/en";
import axios from "axios";
import { convertUrl } from "../../utils/imgUrl";

const useStyles = makeStyles((theme) => ({
  ceo: {
    width: "800px",
    margin: "0 auto",
  },
  img: {
    width: "100%",
  },
  divImage: {
    width: "120px",
    margin: "60px auto 30px auto",
    backgroundColor: "white",
  },
  divImage1: {
    width: "130px",
    margin: "60px auto 30px auto",
    backgroundColor: "white",
  },
  divImage2: {
    width: "400px",
    margin: "60px auto 30px auto",
    backgroundColor: "white",
  },
  divImage3: {
    width: "150px",
    margin: "60px auto 44px auto",
    backgroundColor: "white",
  },
  divImage4: {
    width: "150px",
    margin: "60px auto 30px auto",
    backgroundColor: "white",
  },
  divImage5: {
    width: "220px",
    margin: "60px auto 30px auto",
    backgroundColor: "white",
  },
  divImage6: {
    width: "300px",
    margin: "60px auto 0px auto",
    backgroundColor: "white",
  },
  flexDiv: {
    display: "flex",
    justifyContent: "center",
  },
  flexItem: {
    padding: "60px",
  },
  container: {
    textAlign: "center",
    position: "relative",
    fontSize: "18px",
    fontFamily: "Times New Roman",
    margin: "0 auto",
  },
  verticalLine: {
    position: "absolute",
    width: "1px",
    height: "100%",
    backgroundColor: "black",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: -1,
  },
  svg: {
    position: "fixed",
    width: "100vw",
    height: "calc(100vh - 124px)",
    top: "100px",
    zIndex: -1,
  },
  description: {
    backgroundColor: "white",
    padding: "20px",
    "& h4": {
      fontSize: "28px",
      paddingBottom: "10px",
    },
  },
  missionTitle: {
    fontSize: "30px",
    color: "#fe5600",
    marginBottom: "10px",
  },
  mission: {
    width: "635px",
    margin: "0 auto",
    fontSize: "16px",
  },
  leaderContainer: {
    backgroundColor: "white",
    fontSize: "16px",
    "& p": {
      marginTop: "15px",
      marginBottom: "15px",
    },
  },
  nameLead: {
    fontSize: "24px",
    borderBottom: "1px solid",
    width: "220px",
    margin: "0 auto",
    position: "relative",
    paddingBottom: "5px",
  },
  borderLine: {
    position: "absolute",
    width: "70px",
    height: "4px",
    backgroundColor: "black",
    bottom: -2.5,
    left: "50%",
    transform: "translateX(-50%)",
    borderRadius: "10px",
  },
  footerDiv: {
    backgroundImage: `url(${convertUrl(listImageAboutUs[7])})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  employee: {
    width: "1280px",
    margin: "0 auto",
  },
  imageSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "100px",
  },
  "@media (max-width: 1024px)": {
    mission: {
      width: "100%",
      margin: "0 auto",
      fontSize: "16px",
    },
    employee: {
      width: "100%",
    },
  },
  imageSection: {
    display: "none",
  },
  "@media (max-width: 640px)": {
    mission: {
      width: "100%",
      margin: "0 auto",
      fontSize: "16px",
    },
    employee: {
      width: "100%",
    },
    ceo: {
      width: "350px",
      margin: "0 auto",
      overflowX: "hidden",
      // "& *": {
      //     width: "98vw"
      // },
      // "& > p": {
      //     width: "300px"
      // }
    },
  },
  imageSection: {
    display: "none",
  },
}));

const History = () => {
  const classes = useStyles();
  const lang = useSelector((state) => state.lang.lang);
  const langAbout = !lang ? language.en.about : language.vi.about;
  let [historyList, setHistoryList] = useState([]);
  let [leader, setLeader] = useState([]);
  const url = "https://api.themoderntouch.co/wp-json/wp/v2/history?acf_format=standard";
  useEffect(() => {
    axios.get(`${url}`).then((response) => {
      setHistoryList(response.data);
    });
  }, []);
  useEffect(() => {
    axios.get('https://api.themoderntouch.co/wp-json/wp/v2/staff?acf_format=standard').then((response) => {
      setLeader(response.data);
    });
  }, []);

  useEffect(() => {
    AOS.init({ once: true });
    AOS.refresh();
  }, []);
  const ref = useRef(null);

  useEffect(() => {
    const path = ref.current;

    const pathLength = path.getTotalLength();
    path.style.strokeDasharray = pathLength + " " + pathLength;
    path.style.strokeDashoffset = pathLength;
    window.addEventListener("scroll", () => {
      var scrollPercentage =
        (document.documentElement.scrollTop + document.body.scrollTop) /
        (document.documentElement.scrollHeight +
          document.documentElement.clientHeight);
      var drawLength = pathLength * scrollPercentage;
      path.style.strokeDashoffset = pathLength - drawLength;
    });
  }, []);
  return (
    <div className={classes.container}>
      <div className={classes.svg}>
        <svg id="chart" height="100%" style={{ backgroundClip: "red", width: '100%' }}>
          <line
            ref={ref}
            x1="50%"
            y1="0"
            x2="50%"
            y2="100%"
            stroke="#fe5600"
            strokeWidth="3px"
          ></line>
        </svg>
      </div>

      <div className={classes.divImage}>
        <img
          data-aos="fade-up"
          data-aos-easing="ease"
          data-aos-duration="1000"
          className={classes.img}
          src={historyList[0]?.acf.image}
          alt=""
        />
      </div>
      <div className={classes.description}>
        <h4
          data-aos="fade-up"
          data-aos-easing="ease"
          data-aos-duration="1000"
          data-aos-delay="100"
        >
          {historyList[0]?.acf.year}
        </h4>
        <p
          data-aos="fade-up"
          data-aos-easing="ease"
          data-aos-duration="1000"
          data-aos-delay="150"
        >
          {historyList[0]?.acf.location}
        </p>
        <p
          data-aos="fade-up"
          data-aos-easing="ease"
          data-aos-duration="1000"
          data-aos-delay="200"
          dangerouslySetInnerHTML={{ __html: lang ? historyList[0]?.acf.description_vi : historyList[0]?.content.rendered }}
        >

        </p>
      </div>
      <div className={classes.divImage1}>
        <img
          data-aos="fade-up"
          data-aos-easing="ease"
          data-aos-duration="1000"
          className={classes.img}
          src={historyList[1]?.acf.image}
          alt=""
        />
      </div>
      <div className={classes.description}>
        <h4
          data-aos="fade-up"
          data-aos-easing="ease"
          data-aos-duration="1000"
          data-aos-delay="100"
        >
          {historyList[1]?.acf.year}
        </h4>
        {/* <p data-aos="fade-up"
                    data-aos-easing="ease"
                    data-aos-duration="1000"
                    data-aos-delay="150">7 Staffs</p> */}
        <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="2000">
          {lang ? historyList[1]?.acf.location_vi : historyList[1]?.acf.location}
        </p>
      </div>
      <div className={classes.divImage2}>
        <img
          data-aos="fade-up"
          data-aos-easing="ease"
          data-aos-duration="1000"
          className={classes.img}
          src={historyList[2]?.acf.image}
          alt=""
        />
      </div>
      <div className={classes.description}>
        <h4
          data-aos="fade-up"
          data-aos-easing="ease"
          data-aos-duration="1000"
          data-aos-delay="100"
        >
          {historyList[2]?.acf.year}
        </h4>
        {/* <p data-aos="fade-up"
                    data-aos-easing="ease"
                    data-aos-duration="1000"
                    data-aos-delay="150">7 Staffs</p> */}
        <p
          data-aos="fade-up"
          data-aos-easing="ease"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          {lang ? historyList[2]?.acf.location_vi : historyList[2]?.acf.location}
        </p>
      </div>
      <div className={classes.flexDiv}>
        <div className={classes.flexItem}>
          <div className={classes.divImage4}>
            <img
              data-aos="fade-up"
              data-aos-easing="ease"
              data-aos-duration="1000"
              className={classes.img}
              src={convertUrl(historyList[3]?.acf.image)}
              alt=""
            />
          </div>
          <div className={classes.description}>
            <h4
              data-aos="fade-up"
              data-aos-easing="ease"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              {historyList[3]?.acf.year}
            </h4>
            {/* <p data-aos="fade-up"
                            data-aos-easing="ease"
                            data-aos-duration="1000"
                            data-aos-delay="150">35 Staffs</p> */}
            <p
              data-aos="fade-up"
              data-aos-easing="ease"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              {lang ? historyList[3]?.acf.location_vi : historyList[3]?.acf.location}
            </p>
          </div>
        </div>
        <div className={classes.flexItem}>
          <div className={classes.divImage3}>
            <img
              data-aos="fade-up"
              data-aos-easing="ease"
              data-aos-duration="1000"
              className={classes.img}
              src={convertUrl(historyList[4]?.acf.image)}
              alt=""
            />
          </div>
          <div className={classes.description}>
            <h4
              data-aos="fade-up"
              data-aos-easing="ease"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              {historyList[4]?.acf.year}
            </h4>
            {/* <p data-aos="fade-up"
                            data-aos-easing="ease"
                            data-aos-duration="1000"
                            data-aos-delay="150">35 Staffs</p> */}
            <p
              data-aos="fade-up"
              data-aos-easing="ease"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              {lang ? historyList[4]?.acf.location_vi : historyList[4]?.acf.location}
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className={classes.divImage5}>
          <img
            data-aos="fade-up"
            data-aos-easing="ease"
            data-aos-duration="1000"
            className={classes.img}
            src={convertUrl(historyList[5]?.acf.image)}
            alt=""
          />
        </div>
        <div className={classes.description}>
          <h4
            data-aos="fade-up"
            data-aos-easing="ease"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            {lang ? "HIỆN TẠI" : "NOW"}
          </h4>
          {/* <p data-aos="fade-up"
                        data-aos-easing="ease"
                        data-aos-duration="1000"
                        data-aos-delay="150">60 Staffs</p> */}
          <p
            data-aos="fade-up"
            data-aos-easing="ease"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            {lang ? historyList[5]?.acf.location_vi : historyList[5]?.acf.location}
          </p>
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          marginTop: "30px",
          backgroundColor: "white",
          padding: "10px",
        }}
      >
        <h4
          data-aos="fade-up"
          data-aos-easing="ease"
          data-aos-duration="1000"
          className={classes.missionTitle}
        >
          {lang ? 'THÔNG ĐIỆP & TẦM NHÌN' : 'VISION & MISSION'}
        </h4>
        <p
          data-aos="fade-up"
          data-aos-easing="ease"
          data-aos-duration="1000"
          data-aos-delay="100"
          className={classes.mission}
        >
          {lang ? 'Với tầm nhìn trở thành công ty Tư vấn Thiết kế dẫn đầu trong phân khúc “Premium & Luxury”, The MODERN TOUCH luôn mang trong mình sứ mệnh “Quality Design Quality Life” – “Kiến tạo chất lượng cuộc sống” cho riêng mỗi khách hàng theo tiêu chuẩn 5Đ: Đúng – Đủ – Đáng – Đẹp – Độc Đáo'
            : 'With the vision of becoming a leading Design Consulting company in the “Premium & Luxury” class, The Modern Touch always carries with us the mission “Quality Design - Quality Life” - “Build quality of life” for each client according to the 5D standards: Precise - Sufficient - Worth - Beautiful - Unique.'}
        </p>
      </div>
      <div className={classes.leaderContainer}>
        <div className={classes.divImage6}>
          <img
            data-aos="fade-up"
            data-aos-easing="ease"
            data-aos-duration="1000"
            className={classes.img}
            style={{ marginTop: "-30px" }}
            src={leader?.filter(item => item.acf.leader === true)[0]?.acf.avatar}
            alt=""
          />
        </div>
        <div
          className={classes.nameLead}
          data-aos="fade-up"
          data-aos-easing="ease"
          data-aos-duration="1000"
          data-aos-delay="100"
        >
          <h4>{lang ? leader?.filter(item => item.acf.leader === true)[0]?.acf.title_vi : leader?.filter(item => item.acf.leader === true)[0]?.title.rendered}</h4>
          <div className={classes.borderLine}></div>
        </div>
        <div className={classes.ceo}>
          <h4
            dangerouslySetInnerHTML={{
              __html: lang ? leader?.filter(item => item.acf.leader === true)[0]?.acf.position_vi : leader?.filter(item => item.acf.leader === true)[0]?.acf.position,
            }}
            data-aos="fade-up"
            data-aos-easing="ease"
            data-aos-duration="1000"
            style={{
              fontSize: "18px",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: lang ? leader?.filter(item => item.acf.leader === true)[0]?.acf.description_vi : leader?.filter(item => item.acf.leader === true)[0]?.content.rendered,
            }}
          />
        </div>
        <div className={classes.employee}>
          <Employee />
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: "30px",
            backgroundColor: "white",
            padding: "10px",
            maxWidth: "1280px",
            margin: "0 auto",
          }}
        >
          <h4
            data-aos="fade-up"
            data-aos-easing="ease"
            data-aos-duration="1000"
            className={classes.missionTitle}
          >
            {lang ? 'Đội ngũ tận tâm và chuyên nghiệp' : 'DEDICATED & PROFESSIONAL TEAM'}
          </h4>
          <p
            data-aos="fade-up"
            data-aos-easing="ease"
            data-aos-duration="1000"
            data-aos-delay="100"
            style={{ fontSize: "16px" }}
          >
            {lang ? 'The MODERN TOUCH sở hữu đội ngũ hơn 60 nhân sự gồm kiến trúc sư, kỹ sư, thiết kế, quản lý dự án, tư vấn giám sát…, tham gia nhiều dự án lớn trong và ngoài nước với các vai trò: quy hoạch, thiết kế kiến trúc, thiết kế nội thất, triển khai hồ sơ, quản lý dự án, tư vấn giám sát, xây dựng,… Mỗi thành viên, dù ở vị trí nào, cũng đều tham gia vào toàn bộ quá trình phát triển dự án, phát huy tối đa tài năng, phẩm chất ưu tú và thế mạnh của mình cùng tạo nên giá trị lớn hơn cho chính bản thân, tập thể công ty, khách hàng, đối tác và cộng đồng.'
              : 'THE MODERN TOUCH owns a team of more than 60 employees including architects, engineers, designers, project managers, supervision consultants..., participating in many large domestic and foreign projects with the following roles: planning, architectural design, interior design, document design, project management, supervision consultancy, construction, etc. Each member, regardless of position, is involved in the entire project development process, making the most of their talents, elite qualities, and strengths to create greater value for themselves, the company, clients, partners, and the community.'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default History;
