import img1 from "../assets/images/aboutUs/Aboutus1.png";
import img2 from "../assets/images/aboutUs/Aboutus2.png";
import img3 from "../assets/images/aboutUs/Aboutus3.png";
import img4 from "../assets/images/aboutUs/Aboutus4.png";
import img5 from "../assets/images/aboutUs/Aboutus5.png";
import img6 from "../assets/images/aboutUs/Aboutus6.png";
import img7 from "../assets/images/aboutUs/Aboutus7.png";
import img8 from "../assets/images/aboutUs/Aboutus8.png";
import img9 from "../assets/images/aboutUs/Aboutus9.png";
import img10 from "../assets/images/aboutUs/Aboutus10.png";
import img11 from "../assets/images/aboutUs/Aboutus11.png";
import img12 from "../assets/images/aboutUs/Aboutus12.png";
import img13 from "../assets/images/aboutUs/Aboutus13.png";
import img14 from "../assets/images/aboutUs/Aboutus14.png";
import img15 from "../assets/images/aboutUs/Aboutus15.png";
import img16 from "../assets/images/aboutUs/Aboutus16.png";
import img17 from "../assets/images/aboutUs/Aboutus17.png";
import img18 from "../assets/images/aboutUs/Aboutus18.png";
import img19 from "../assets/images/aboutUs/aboutus19.png";
import img20 from "../assets/images/aboutUs/Aboutus20.jpg";
import img21 from "../assets/images/aboutUs/Aboutus21.jpg";
export const listImageAboutUs = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img18,
  img19,
  img20,
  img21,
];

export const listEmployee = [
  {
    avata: img8,
    name: "CAO LU THANH LONG",
    sub: "Acting Deputy Managing Director",
    viname: "CAO LỮ THANH LONG",
    visub: "PHÓ GIÁM ĐỐC ĐIỀU HÀNH",
  },
  {
    avata: img9,
    name: "NGUYEN QUOC VINH",
    sub: "VietNam Northern & Central Branch Manager",
    viname: "NGUYỄN QUỐC VINH",
    visub: "GIÁM ĐỐC CHI NHÁNH MIỀN BẮC & TRUNG",
  },
  {
    avata: img10,
    name: "TA TRUONG DONG",
    sub: "Finance Manager",
    viname: "TẠ TRƯỜNG ĐÔNG",
    visub: "TRƯỞNG PHÒNG KẾ TOÁN TÀI CHÍNH",
  },
  {
    avata: img11,
    name: "LE THI MINH TUYET",
    sub: "HR & Admin. Manager",
    viname: "LÊ THỊ MINH TUYẾT",
    visub: "TRƯỞNG PHÒNG HÀNH CHÍNH - NHÂN SỰ",
  },
  {
    avata: img12,
    name: "PHAM MINH SANG",
    sub: "Project Manager",
    viname: "PHẠM MINH SANG",
    visub: "TRƯỞNG PHÒNG QUẢN LÝ DỰ ÁN",
  },
  {
    avata: img13,
    name: "HONG NHU NGOC",
    sub: "Marketing & Sourcing Manager",
    viname: "HỒNG NHƯ NGỌC",
    visub: "TRƯỜNG PHÒNG MARKETING & SOURCING",
  },
  {
    avata: img14,
    name: "PHAM THI HA MI",
    sub: "QS - Bidding Manager",
    viname: "PHẠM THỊ HÀ MI",
    visub: "TRƯỞNG PHÒNG QS - ĐẤU THẦU",
  },
  {
    avata: img15,
    name: "NGUYEN THI PHUONG NGA",
    sub: "Arch. Design Deputy Head of Division",
    viname: "NGUYỄN THỊ PHƯƠNG NGA",
    visub: "PHÓ BỘ PHẬN THIẾT KẾ KIẾN TRÚC",
  },
  {
    avata: img16,
    name: "NGUYEN BUI THUC KHUONG",
    sub: "Arch. Design Deputy Head of Division",
    viname: "NGUYỄN BÙI THÚC KHƯƠNG",
    visub: "PHÓ BỘ PHẬN THIẾT KẾ KIẾN TRÚC",
  },
  {
    avata: img17,
    name: "NGUYEN GIA VUNG",
    sub: "MEP Deputy Head of Division",
    viname: "NGUYỄN GIA VỮNG",
    visub: "PHÓ BỘ PHẬN THIẾT KẾ MEP",
  },
];
