// import React, { useEffect, useState } from 'react'
// import Slider from "react-slick";
// import "./Slider.css"
// import { Swiper, SwiperSlide } from "swiper/react";
// import beforeMobile from "../../../assets/images/banner mobile/beforeMobile.png"
// import axios from 'axios';
// import { Autoplay, Pagination, Navigation } from "swiper";
// import "swiper/css/pagination";
// import { convertUrl } from '../../../utils/imgUrl';



// const Banner = () => {
//     const [zoom, setZoom] = useState(false)
//     const [homeContainer, sethomeContainer] = useState('')
//     const [homeContainer2, sethomeContainer2] = useState('')
//     const [homeContainer3, sethomeContainer3] = useState('')
//     const [homeContainer4, sethomeContainer4] = useState('')
//     const [homeContainer5, sethomeContainer5] = useState('')
//     const [homeBanner, sethomeBanner] = useState('')
//     useEffect(() => {
//         const getDetailApi = async () => {
//             try {
//                 const res = await axios.get('https://api.themoderntouch.co/wp-json/wp/v2/pages?slug=home&acf_format=standard');

//                 sethomeContainer(res.data[0].acf.large_banner)
//                 sethomeBanner(res.data[0].acf.small_banner)
//                 sethomeContainer2(res.data[0].acf.banner2)
//                 sethomeContainer3(res.data[0].acf.banner3)
//                 sethomeContainer4(res.data[0].acf.banner4)
//                 sethomeContainer5(res.data[0].acf.banner5)
//             } catch (error) {

//             }
//         };
//         getDetailApi()
//     }, [])
//     const zoomable = () => {
//         if (window.scrollY > 1) {
//             setZoom(true)
//         }
//     }

//     const settings = {
//         // infinite: true,
//         speed: 1000,
//         // autoplay: true,
//         autoplaySpeed: 3000,
//         arrows: false,
//         // draggable:false
//     };

//     window.addEventListener("scroll", zoomable)

//     const useViewport = () => {
//         const [width, setWidth] = React.useState(window.innerWidth);

//         React.useEffect(() => {
//             const handleWindowResize = () => setWidth(window.innerWidth);
//             window.addEventListener("resize", handleWindowResize);
//             return () => window.removeEventListener("resize", handleWindowResize);
//         }, []);

//         return { width };
//     };

//     const viewPort = useViewport();
//     const isMobile = viewPort.width <= 1024;
//     if (isMobile) {
//         return (
//             <div style={{
//                 width: "100%",
//                 height: "auto",
//                 aspectRatio: "16/9"
//             }}>
//                 <Swiper
//                     loop={true}
//                     spaceBetween={30}
//                     centeredSlides={true}
//                     autoplay={{
//                         delay: 3000,
//                         disableOnInteraction: false,
//                     }}
//                     speed={1000}
//                     pagination={{
//                         clickable: true,
//                         bottom: '-10px'
//                     }}
//                     modules={[Autoplay, Pagination]}
//                     className="mySwiper"
//                     style={{
//                         "--swiper-pagination-color": "#fe5600",
//                         "--swiper-pagination-bullet-inactive-color": "#999999",
//                         "--swiper-pagination-bullet-inactive-opacity": "1",
//                         "--swiper-pagination-bullet-size": "10px",
//                         "--swiper-pagination-bullet-horizontal-gap": "5px",
//                         height: "300px"
//                     }}
//                 >
//                     <SwiperSlide>
//                         <div style={{ height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
//                             <img src={convertUrl(homeBanner)} alt='homebanner' style={{ width: "100%" }} />
//                         </div>
//                     </SwiperSlide>
//                     <SwiperSlide>
//                         <div style={{ height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
//                             <img src={convertUrl(homeContainer2)} alt='homebanner' style={{ width: "100%" }} />

//                         </div>
//                     </SwiperSlide>
//                     <SwiperSlide>
//                         <div style={{ height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
//                             <img src={convertUrl(homeContainer3)} alt='homebanner' style={{ width: "100%" }} />

//                         </div>
//                     </SwiperSlide>
//                     <SwiperSlide>
//                         <div style={{ height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
//                             <img src={convertUrl(homeContainer4)} alt='homebanner' style={{ width: "100%" }} />

//                         </div>
//                     </SwiperSlide>
//                     <SwiperSlide>
//                         <div style={{ height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
//                             <img src={convertUrl(homeContainer5)} alt='homebanner' style={{ width: "100%" }} />

//                         </div>
//                     </SwiperSlide>

//                 </Swiper>
//             </div>
//         )
//     }

//     return (
//         <div className='container'
//             style={zoom ? { transform: "scale(1)", transition: "all 1s linear" } : { transform: "scale(2.7)", transition: "all 1s linear" }}
//         >
//             {zoom ? <Swiper
//                 loop={true}
//                 spaceBetween={30}
//                 centeredSlides={true}
//                 autoplay={{
//                     delay: 7000,
//                     disableOnInteraction: false,
//                 }}
//                 speed={1000}
//                 pagination={{
//                     clickable: true,
//                     bottom: '-10px'
//                 }}
//                 // pagination={true}
//                 // navigation={true}
//                 modules={[Autoplay, Pagination]}
//                 className="mySwiper"
//                 // style={{ paddingBottom: "14px" }}
//                 style={{
//                     "--swiper-pagination-color": "#fe5600",
//                     "--swiper-pagination-bullet-inactive-color": "#999999",
//                     "--swiper-pagination-bullet-inactive-opacity": "1",
//                     "--swiper-pagination-bullet-size": "10px",
//                     "--swiper-pagination-bullet-horizontal-gap": "5px"
//                 }}
//             >
//                 <SwiperSlide>
//                     <div className='zoom' style={{ backgroundImage: `url(${convertUrl(homeContainer)})` }}>
//                         <div>
//                             <Slider className='slider' {...settings}>
//                                 <div className='sliderItem active'>
//                                     <img style={{ height: "calc((100vh-120px)/3+22px)" }} src={convertUrl(homeBanner)} alt="" />
//                                 </div>

//                             </Slider>
//                         </div>
//                     </div>
//                 </SwiperSlide>
//                 <SwiperSlide>
//                     <div className='zoom' style={{ backgroundImage: `url(${convertUrl(homeContainer2)})` }}>

//                     </div>
//                 </SwiperSlide>
//                 <SwiperSlide>
//                     <div className='zoom' style={{ backgroundImage: `url(${convertUrl(homeContainer3)})` }}>

//                     </div>
//                 </SwiperSlide>
//                 <SwiperSlide>
//                     <div className='zoom' style={{ backgroundImage: `url(${convertUrl(homeContainer4)})` }}>

//                     </div>
//                 </SwiperSlide>
//                 <SwiperSlide>
//                     <div className='zoom' style={{ backgroundImage: `url(${convertUrl(homeContainer5)})` }}>

//                     </div>
//                 </SwiperSlide>
//             </Swiper>
//                 : <div className='zoom' style={{ backgroundImage: `url(${convertUrl(homeContainer)})` }}>
//                     <div>
//                         <Slider className='slider' {...settings}>
//                             <div className='sliderItem' >
//                                 <img style={{ height: "calc((100vh-120px)/3+22px)" }} src={convertUrl(homeBanner)} alt="" />
//                             </div>

//                         </Slider>
//                     </div>
//                 </div>}
//         </div >
//     )
// }

// export default Banner
import gsap from 'gsap'
import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Pagination } from "swiper";
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const Banner = () => {
    const [data, setData] = useState(null)
    useEffect(() => {
        const response = axios.get(`https://api.themoderntouch.co/wp-json/wp/v2/pages?slug=home&acf_format=standard`).then(res => setData(res.data[0].acf))
    }, [])
    const banner = useRef(null)
    const bannerSmall = useRef(null)
    const [done, setDone] = useState(false)
    const location = useLocation()
    // console.log(data);
    useEffect(() => {
        setDone(false)
    }, [location])
    useEffect(() => {

        if (window.innerWidth > 1023) {

            banner.current && gsap.set(banner.current, {
                scale: 3,
                zIndex: 11
            })
            window.addEventListener('wheel', () => {
                banner.current && gsap.to(banner.current, {
                    scale: 1,
                    zIndex: 0,
                    duration: 2,
                    ease: 'easeOut',
                    onComplete: () => setDone(true)
                })
            })
            return
        } else {

            setDone(true)
        }
    }, [done])
    return (
        <>{done
            ? <Swiper
                loop={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                speed={1000}
                pagination={{
                    clickable: true,
                    bottom: '-10px'
                }}
                modules={[Pagination]}
                className="mySwiper h-[200px] md:h-[600px] lg:h-[calc(100vh-124px)]"
                style={{
                    "--swiper-pagination-color": "#fe5600",
                    "--swiper-pagination-bullet-inactive-color": "#999999",
                    "--swiper-pagination-bullet-inactive-opacity": "1",
                    "--swiper-pagination-bullet-size": "12px",
                    "--swiper-pagination-bullet-horizontal-gap": "5px",
                }}
            >

                <SwiperSlide
                    style={{
                        backgroundImage: `url(${data?.large_banner})`,
                        // backgroundPosition: 'center',
                        // height: "calc(100vh - 124px)",
                        backgroundRepeat: 'no-repeat',
                    }}
                    className='w-screen bg-[center] bg-cover lg:bg-cover lg:h-screen aspect-video lg:aspect-auto flex justify-center items-center'>
                    <div ref={bannerSmall} className='w-1/3 md:w-auto md:h-[240px] lg:h-[373px] aspect-video flex justify-center items-center'>
                        <img className='h-[91%]' src={data?.small_banner} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide className='w-screen bg-[center] bg-contain lg:bg-cover lg:h-screen aspect-video lg:aspect-auto flex justify-center items-center '>
                    <img className='h-full' src={data?.banner2} alt="" />
                </SwiperSlide>
                <SwiperSlide className='w-screen bg-[center] bg-contain lg:bg-cover lg:h-screen aspect-video lg:aspect-auto flex justify-center items-center '>
                    <img className='h-full' src={data?.banner3} alt="" />
                </SwiperSlide>
                <SwiperSlide className='w-screen bg-[center] bg-contain lg:bg-cover lg:h-screen aspect-video lg:aspect-auto flex justify-center items-center '>

                    <img className='h-full' src={data?.banner4} alt="" />

                </SwiperSlide>
                <SwiperSlide className='w-screen bg-[center] bg-contain lg:bg-cover lg:h-screen aspect-video lg:aspect-auto flex justify-center items-center '>
                    <img className='h-full' src={data?.banner5} alt="" />
                </SwiperSlide>




            </Swiper >

            : <div
                ref={banner}
                style={{
                    backgroundImage: `url(${data?.large_banner})`,

                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
                className='w-screen h-screen  flex justify-center items-center'>
                <div ref={bannerSmall} style={{

                }} className='h-[160px]md:h-[240px] lg:h-[373px] aspect-video flex justify-center items-center'>
                    <img className='h-[91%]' src={data?.small_banner} alt="" />
                </div>
            </div >}
        </>
    )
}

export default Banner